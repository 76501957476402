import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Wysiwyg from './Wysiwyg'

// styles
// ==========================================================================================
const Wrapper = styled.div`
  margin-bottom: 120px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
  }
`

// component
// ==========================================================================================
const BodyText = ({ meta, body }) => {
  if (!body) {
    return null
  }

  return (
    <Wrapper>
      <Row>
        <Col xs={8} xsOffset={1}>
          <Wysiwyg meta={meta} content={body} />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default BodyText

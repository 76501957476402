import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/Layout'
import Content from '../components/Content'
import Hero from '../components/Hero'
import BodyText from '../components/BodyText'
import Separator from '../components/Separator'

export const query = graphql`
  query GenericQuery($uid: String) {
    prismic {
      documents: allGenerics(uid: $uid) {
        edges {
          node {
            meta: _meta {
              uid
              lang
              type
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroLabel: hero_label
            heroTitle: hero_title
            heroBody: hero_body
            heroImage: hero_image
            heroImageSharp: hero_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            intro
            body
          }
        }
      }
    }
  }
`

export default props => {
  const data = get(props, 'data.prismic.documents.edges[0].node')

  return (
    <Layout {...data}>
      <Hero {...data} />
      <Separator />
      <Content>
        <BodyText {...data} />
      </Content>
    </Layout>
  )
}
